@media only screen and (max-width: 768px) {
  .home-location-sign {
    width: 70px;
    opacity: 0.4;
  }

  .home-location-sign.active {
    width: 80px;
    opacity: 1;
  }

  .location-explore-more .location-sign {
    width: 70px;
  }

  .d-md-show-br br {
    display: inline-block !important;
  }
}