/* Apply to all screen size that width > 1200px */
#main-menu .logo {
  height: 24px;
}

#hero .carousel-caption {
  bottom: unset;
}

#footer .logo {
  height: 24px;
}

#footer .social-icon {
  width: 40px;
}


.custom-nav-dropdown .dropdown-toggle::after {
  /* Set the display property to 'none' to hide the caret icon */
  display: none;
}

.custom-nav-dropdown .nav-link {
  color: #FFF !important;
}

.custom-nav-dropdown .dropdown-menu {
  background-color: transparent;
  border: 0;
  top: 52px;
  left: 15px;
}

.custom-nav-dropdown .dropdown-menu .dropdown-item {
  background-color: var(--color-primary);
  color: #FFF;
  letter-spacing: 1px;
  margin-bottom: 5px;
  padding: 5px 15px;
  white-space: unset;
  width: 140px;
  line-height: 1.2;
}

.custom-nav-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #FFF;
  color: var(--color-primary);
}

.navbar-nav>.nav-link:hover,
.navbar-nav>.dropdown>.nav-link:hover {
  color: var(--color-secondary) !important;
}

.navbar-nav>.nav-link.booking-button:hover {
  color: #FFF !important;
}

.navbar-nav>.nav-link.active,
.navbar-nav>.nav-item.dropdown.active>a.nav-link {
  color: var(--color-secondary) !important;
}

.bg-custom-secondary .custom-nav-dropdown .dropdown-menu .dropdown-item {
  background-color: rgba(217, 186, 121, 0.6);
  color: #FFF;
}

.bg-custom-secondary .custom-nav-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #FFF;
  color: rgba(217, 186, 121, 1);
}

.bg-custom-primary {
  background-color: rgba(93, 105, 67, 0.6);
}

.bg-custom-secondary {
  background-color: rgba(217, 186, 121, 0.6);
}

.bg-custom-secondary .navbar-nav>.nav-link:hover,
.bg-custom-secondary .navbar-nav>.dropdown>.nav-link:hover {
  color: var(--color-dark) !important;
}

.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.glamping-indicator-primary .carousel-indicators,
.glamping-indicator-secondary .carousel-indicators,
.glamping-indicator-tertiary .carousel-indicators,
.ongrid-indicator-primary .carousel-indicators,
.ongrid-indicator-secondary .carousel-indicators,
.offgrid-indicator-primary .carousel-indicators,
.carcamping-indicator-primary .carousel-indicators,
.location-indicator .carousel-indicators {
  margin: 0 !important;
  bottom: -40px;
}

.glamping-indicator-primary .carousel-indicators button,
.glamping-indicator-secondary .carousel-indicators button,
.glamping-indicator-tertiary .carousel-indicators button,
.ongrid-indicator-primary .carousel-indicators button,
.ongrid-indicator-seconday .carousel-indicators button,
.offgrid-indicator-secondary .carousel-indicators button,
.carcamping-indicator-secondary .carousel-indicators button,
.location-indicator .carousel-indicators button {
  margin-left: 7px;
  margin-right: 7px;
}

.glamping-indicator-primary .carousel-indicators {
  justify-content: left;
}

.glamping-indicator-primary .carousel-indicators button {
  background-color: var(--color-primary-indicator);
}

.glamping-indicator-primary .carousel-indicators button.active {
  background-color: var(--color-primary-indicator-active);
}

.glamping-indicator-secondary .carousel-indicators {
  justify-content: right;
}

.glamping-indicator-secondary .carousel-indicators button {
  background-color: var(--color-secondary-indicator);
}

.glamping-indicator-secondary .carousel-indicators button.active {
  background-color: var(--color-secondary-indicator-active);
}

.glamping-indicator-tertiary .carousel-indicators {
  justify-content: right;
}

.glamping-indicator-tertiary .carousel-indicators button {
  background-color: var(--color-secondary-indicator);
}

.glamping-indicator-tertiary .carousel-indicators button.active {
  background-color: var(--color-secondary-indicator-active);
}

.ongrid-indicator-primary .carousel-indicators {
  justify-content: right;
}

.ongrid-indicator-primary .carousel-indicators button {
  background-color: var(--color-primary-indicator);
}

.ongrid-indicator-primary .carousel-indicators button.active {
  background-color: var(--color-primary-indicator-active);
}

.ongrid-indicator-secondary .carousel-indicators {
  justify-content: left;
}

.ongrid-indicator-secondary .carousel-indicators button {
  background-color: var(--color-secondary-indicator);
}

.ongrid-indicator-secondary .carousel-indicators button.active {
  background-color: var(--color-secondary-indicator-active);
}

.offgrid-indicator-primary .carousel-indicators {
  justify-content: left;
}

.offgrid-indicator-primary .carousel-indicators button {
  background-color: var(--color-primary-indicator);
}

.offgrid-indicator-primary .carousel-indicators button.active {
  background-color: var(--color-primary-indicator-active);
}

.carcamping-indicator-primary .carousel-indicators {
  justify-content: right;
}

.carcamping-indicator-primary .carousel-indicators button {
  background-color: var(--color-primary-indicator);
}

.carcamping-indicator-primary .carousel-indicators button.active {
  background-color: var(--color-primary-indicator-active);
}

.location-indicator .carousel-indicators {
  justify-content: left;
}

.location-indicator .carousel-indicators button {
  background-color: var(--color-secondary-indicator);
}

.location-indicator .carousel-indicators button.active {
  background-color: var(--color-secondary-indicator-active);
}

.custom-ratio-slide {
  padding-bottom: 61.87% !important;
}

.custom-ratio-home-accom {
  padding-bottom: 45% !important;
}

.header-lg {
  font-size: 4.2rem;
  text-shadow: 8px 8px 8px #222;
  letter-spacing: 4px;
  line-height: 0.9;
  z-index: 99;
  padding-left: 4rem;
  padding-right: 4rem;
}

.subheader-lg {
  z-index: 99;
  font-size: 1.45rem;
  font-weight: bold;
  letter-spacing: 4px;
  text-shadow: 3px 3px 10px #333;
  color: var(--body-bg);
  padding-left: 4rem;
  padding-right: 4rem;
}

.custom-header {
  z-index: 99;
  padding-top: 0px;
  padding-bottom: 60px;
  /* margin-bottom: 60px; */
  margin-bottom: 0px;
  margin-top: -8rem;
}

.custom-body-bg {
  margin-top: -215px;
  height: unset;
  margin-bottom: unset;
}

.ongrid.custom-header {
  z-index: 99;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: -8rem;
}

.ongrid .custom-body-bg {
  margin-top: -215px;
  height: unset;
  margin-bottom: unset;
}

.ongrid .overlap-content {
  margin-top: 80px;
}

.offgrid.custom-header {
  z-index: 99;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: -8rem;
}

.offgrid .custom-body-bg {
  margin-top: -215px;
  height: unset;
  margin-bottom: unset;
}

.offgrid .custom-text {
  margin-top: -40px;
}

.carcamping.custom-header {
  z-index: 99;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: -8rem;
}

.carcamping .custom-body-bg {
  margin-top: -215px;
  height: unset;
  margin-bottom: unset;
}

.carcamping .custom-text {
  margin-top: -40px;
}


.custom-body-content {
  padding-top: 260px;
  padding-bottom: 60px;
  margin-bottom: 60px;
  width: 100%;
}

.bg-custom-height {
  height: calc(100% - 180px) !important;
}

.bg-custom-height2 {
  height: calc(100% - 248px) !important;
}

.bg-custom-height3 {
  height: calc(100% - 174px) !important;
}

#main-menu {
  z-index: 99999;
}

.fs-caption-1 {
  font-size: 4rem;
  line-height: 1;
}

.fs-custom-1 {
  font-size: 1.8rem !important;
}

.fs-custom-2 {
  font-size: 1.8rem !important;
}

.fs-custom-3 {
  font-size: 1.8rem !important;
}

.fs-custom-5 {
  font-size: 1rem !important;
  line-height: 1.3;
}

.fs-custom-6 {
  font-size: 1.8rem !important;
}

.lh-12 {
  line-height: 1.2;
}

.bg-custom-height {
  height: 200px;
}

.mb-home-accom {
  margin-bottom: 100px;
}

.mt-home-location {
  margin-top: 130px;
}

.home-location-sign {
  width: 160px;
  opacity: 0.4;
}

.home-location-sign.active {
  width: 220px;
  opacity: 1;
}

.btn-explore {
  width: 12rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.2rem;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 2px;
}

.btn-booknow {
  width: 12.5rem;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 2px;
}

.navbar-toggler {
  border: 0;
  filter: brightness(100);
}

#footer .bg-bar,
#footer .footer-content {
  height: 80px;
}

#footer .container-footer {
  height: 160px;
}

#footer .container-bg-gradient {
  height: 40%;
  background: linear-gradient(180deg, rgba(228, 220, 209, 1) 0%, rgba(228, 220, 209, 0) 100%);
}

.offcanvas {
  background-color: transparent;
  text-align: right;
  border: 0;
  border-left: 0 !important;
}

.offcanvas-backdrop.show {
  opacity: 0;
}

.offcanvas.show .offcanvas-body .nav-link {
  width: auto;
  display: inline-flex;
  align-self: self-end;
  background-color: var(--color-primary);
  margin-bottom: 4px;
  font-size: 0.9rem !important;
}

.offcanvas.show .offcanvas-body .nav-link.booking-button {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.offcanvas.show .custom-nav-dropdown .nav-link {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.offcanvas.show .dropdown-menu.show {
  text-align: -webkit-right;
}

.offcanvas.show .dropdown-menu.show .dropdown-item {
  text-align: left;
}

.offcanvas .offcanvas-header .btn-close {
  display: none;
}

.navbar-toggler:focus {
  box-shadow: unset;
}

.slide-content {}

.custom-indicator {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  margin-left: 7px;
  margin-right: 7px;
}

.bg-primary-indicator {
  background-color: var(--color-primary-indicator) !important;
}

.bg-primary-indicator.active {
  background-color: var(--color-primary-indicator-active) !important;
}

.bg-secondary-indicator {
  background-color: var(--color-secondary-indicator);
}

.bg-secondary-indicator.active {
  background-color: var(--color-secondary-indicator-active);
}

.content-2 {
  /* margin-top: 15rem; */
  margin-top: 10rem;
}

.content-2.with-booking {
  margin-top: 15rem;
}

.content-2 .slide-object {
  margin-top: -80px;
}

.slide-object-zone {
  margin-top: -80px;
}

.location-header {
  margin-top: -80px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.location-sign {
  width: 10.5rem;
}

.eden .content-text {
  margin-top: -50px;
}

.content-top {
  margin-bottom: 180px;
}

.about .top {
  margin-top: 7rem;
  margin-bottom: 5rem;
}

.about .logo {
  width: 24rem;
}

.booking,
.privacy-policy,
.refund-and-cancallation {
  margin-top: 100px;
}

.booking .sign {
  max-width: 12rem;
  width: 100%;
}

.accom-list:hover .accom-bg,
.accom-list:hover .accom-icon {
  filter: grayscale(100%);
}

.accom-list:hover .accom-item:hover .accom-bg,
.accom-list:hover .accom-item:hover .accom-icon {
  filter: unset;
}

.layer-container.top-layer {
  margin-top: -4.5rem;
}

.layer-container .layer-content-text {
  padding-top: 11rem;
  padding-left: 32%;
  padding-right: 5rem;
  padding-bottom: 5rem;
}

.layer-container .layer-content-text.top-header {
  background-color: var(--color-primary);
  padding-top: 11rem;
  padding-left: 32%;
  padding-right: 5rem;
  padding-bottom: 5rem;
}

.layer-container .layer-content-text.top-header.on-left {
  padding-top: 12rem;
  padding-left: 5rem;
  padding-right: 32%;
  padding-bottom: 5rem;
}

.layer-container .layer-content-text.top-header.with-booking {
  padding-bottom: 5rem;
}

.layer-container .layer-content-text.start {
  background-color: var(--color-primary);
  padding-top: 5rem;
  padding-left: 5rem;
  padding-right: 32%;
  padding-bottom: 5rem;
}

.layer-container .layer-content-slide {
  padding-top: 11rem;
}

.ongrid .layer-container .layer-content-text {
  padding-top: 12rem;
  padding-left: 5rem;
  padding-right: 32%;
  padding-bottom: 5rem;
}

.ongrid .layer-container .layer-content-slide {
  padding-top: 15rem;
}

.offgrid .layer-container .layer-content-slide {
  padding-top: 15rem;
}

.offgrid .layer-container .layer-content-text {
  padding-top: 12rem;
  padding-right: 5rem;
  padding-left: 32%;
  padding-bottom: 5rem;
}

.carcamping .layer-container .layer-content-text {
  padding-top: 11rem;
  padding-left: 5rem;
  padding-right: 32%;
  padding-bottom: 5rem;
}

.carcamping .layer-container .layer-content-slide {
  padding-top: 11rem;
}

.facility-list {
  width: 100%;
  padding: 0;
  margin: 0;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--color-dark);
}

.facility-list li {
  padding: 0;
  margin: 0;
  width: 50%;
  display: inline-block;
}

.facility-list li img {
  width: 20px;
  height: 20px;
}

.location-offer-slide {
  margin-bottom: 0rem;
}

.location-explore-more .location-sign {
  width: 180px;
}

.bg-about {
  width: 170%;
  margin-top: 5rem;
  z-index: -1;
}

.bg-about.left {
  left: 0;
  padding: 3rem calc(70% + 3rem) 3rem 10rem;
}

.bg-about.right {
  right: 0;
  padding: 3rem 10rem 3rem calc(70% + 3rem);
}

.glamping-text-layout {
  padding-top: 3rem;
  padding-bottom: 8rem;
}

.glamping-slide-layout {
  margin-bottom: -12rem;
}

.booking {
  min-height: calc(100vh - 398px);
}

.pdpa-welcome-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--color-primary);
  color: var(--body-bg);
  z-index: 9999;
}

.pdpa-welcome-popup .logo {
  height: 24px;
}

.pdpa-welcome-popup a {
  color: var(--body-bg);
}

.pdpa-welcome-popup button,
.pdpa-welcome-popup button:hover,
.pdpa-welcome-popup button:active {
  color: var(--color-primary) !important;
  background-color: var(--body-bg) !important;
}

.privacy-policy ol {
  padding-left: 20px;
  list-style-type: decimal;
  margin-top: 16px;
}

.privacy-policy ol>li>ol {
  margin-top: 0;
}

.privacy-policy ul {
  padding-left: 20px;
  list-style-type: disc;
  margin-top: 16px;
}

.tx-color-dark {
  color: var(--color-dark);
}

.link-color-body {
  color: var(--body-bg);
}

.footer-content a {
  font-size: 0.8rem;
}

.custom-carousel.right-top-round .carousel-inner .carousel-item {
  border-radius: 0 10em 0 0;
  /*120px*/
}

.custom-carousel.left-top-round .carousel-inner .carousel-item {
  border-radius: 10em 0 0 0;
  /*120px*/
}

/* .d-hide-br br {
  display: none;
}

.d-hide-br br.show-br {
  display: block;
} */