#root,
html,
body {
  width: 100%;
  overflow-x: hidden;
}

body {
  background-color: var(--body-bg);
  overflow-x: hidden;
}

.test-responsive {
  background-color: aquamarine;
}

/* font-family */
.font-default,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span {
  font-family: var(--font-default);
}

.font-header {
  font-family: var(--font-header);
}

.font-handwrite {
  font-family: var(--font-handwrite);
}

/* Custom font family for th language */
/* 
body.th a,
body.th,
body.th h1,
body.th h2,
body.th h3,
body.th h4,
body.th h5,
body.th h6,
*/

body.th .font-default,
body.th p,
body.th span,
body.th li,
body.th .fs-custom-1,
body.th .fs-custom-2,
body.th .fs-custom-3,
body.th .fs-custom-6 {
  font-family: var(--font-th);
}

body.th b {
  font-weight: bold;
}

/* padding-bottom ratio */
.ratio-1x1 {
  padding-bottom: 100%;
}

.ratio-4x3 {
  padding-bottom: 75%;
}

.ratio-16x9 {
  padding-bottom: 56.25%;
}

.ratio-21x9 {
  padding-bottom: 42.85%;
}

.ratio-header {
  padding-bottom: 25%;
}

/* background */
.bg-img {
  background-color: var(--color-lighter);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-color-primary {
  background-color: var(--color-primary);
}

.bg-color-secondary {
  background-color: var(--color-secondary);
}

.bg-color-tertiary {
  background-color: var(--color-tertiary);
}

.bg-color-black {
  background-color: var(--color-black);
}

.bg-color-gray {
  background-color: var(--color-gray);
}

.bg-color-light {
  background-color: var(--color-light);
}

.bg-color-dark {
  background-color: var(--color-dark);
}

.bg-color-white {
  background-color: #FFF;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-size-contain {}

/* border */
.border-color-primary {
  border-color: var(--color-primary);
}

.border-color-secondary {
  border-color: var(--color-secondary);
}

.border-color-tertiary {
  border-color: var(--color-tertiary);
}

.border-color-black {
  border-color: var(--color-black);
}

.border-color-dark {
  border-color: var(--color-dark);
}

/* text */
.tx-color-primary {
  color: var(--color-primary) !important;
}

.tx-color-secondary {
  color: var(--color-secondary) !important;
}

.tx-color-tertiary {
  color: var(--color-tertiary) !important;
}

.tx-spacing-05 {
  letter-spacing: 0.5px;
}

.tx-spacing-10 {
  letter-spacing: 1px;
}

.tx-spacing-15 {
  letter-spacing: 1.5px;
}

.tx-spacing-20 {
  letter-spacing: 2px;
}

.tx-spacing-25 {
  letter-spacing: 2.5px;
}

.tx-spacing-30 {
  letter-spacing: 3px;
}

/* layout */
.as-column-3 {
  column-count: 3;
  /* Adjust the number of columns as needed */
  column-gap: 2rem;
  /* Adjust the gap between columns */
  orphans: 1;
  widows: 1;
}

/* rounded for border-radius */
.rounded-bs-0 {
  border-bottom-left-radius: 0 !important;
}

.rounded-be-0 {
  border-bottom-right-radius: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.img-grayscale {
  filter: grayscale(1);
}

.embed-video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.embed-video-container iframe {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}