
@font-face {
  font-family: 'Gotham';
  src: url('./Fonts/Gotham-BookItalic.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Gotham-BookItalic.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Gotham-BookItalic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Gotham-BookItalic.svg#Gotham-BookItalic') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Gotham';
  src: url('./Fonts/Gotham-Bold.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Gotham-Bold.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Gotham-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Gotham-Bold.svg#Gotham-Bold') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Gotham';
  src: url('./Fonts/Gotham-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Gotham-BoldItalic.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Gotham-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Gotham-BoldItalic.svg#Gotham-BoldItalic') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Gotham';
  src: url('./Fonts/Gotham-Light.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Gotham-Light.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Gotham-Light.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Gotham-Light.svg#Gotham-Light') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: lighter;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Gotham';
  src: url('./Fonts/Gotham-LightItalic.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Gotham-LightItalic.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Gotham-LightItalic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Gotham-LightItalic.svg#Gotham-LightItalic') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: lighter;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Gotham';
  src: url('./Fonts/Gotham-Book.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Gotham-Book.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Gotham-Book.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Gotham-Book.svg#Gotham-Book') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'LidoSTFCE';
  src: url('./Fonts/LidoSTFCE-Bold.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/LidoSTFCE-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/LidoSTFCE-Bold.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/LidoSTFCE-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/LidoSTFCE-Bold.svg#LidoSTFCE-Bold') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'FCMinimal';
  src: url('./Fonts/FCMinimal-Regular.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/FCMinimal-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/FCMinimal-Regular.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/FCMinimal-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/FCMinimal-Regular.svg#FCMinimal-Regular') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'FCMinimal';
  src: url('./Fonts/FCMinimal-Bold.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/FCMinimal-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/FCMinimal-Bold.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/FCMinimal-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/FCMinimal-Bold.svg#FCMinimal-Bold') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'FCMinimal';
  src: url('./Fonts/FCMinimal-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/FCMinimal-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/FCMinimal-BoldItalic.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/FCMinimal-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/FCMinimal-BoldItalic.svg#FCMinimal-BoldItalic') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'FCMinimal';
  src: url('./Fonts/FCMinimal-Italic.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/FCMinimal-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/FCMinimal-Italic.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/FCMinimal-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/FCMinimal-Italic.svg#FCMinimal-Italic') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
