@media only screen and (min-width: 1200px) {
  .test-responsive {
    background-color: tomato;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }
}