@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');

:root {
  /* Breakpoint Variable */
  --breakpoint-xxl: '1400px';
  --breakpoint-xl: '1200px';
  --breakpoint-lg: '992px';
  --breakpoint-md: '768px';
  --breakpoint-sm: '576px';

  /* Color Variable */
  --body-bg: #e4dcd1;

  --color-primary: #5D6943;
  --color-secondary: #FFC301;
  --color-tertiary: #e4dcd1;
  --color-black: #111;
  --color-dark: #1E2337;
  --color-gray: #818F9A;
  --color-light: #AAB3BB;
  --color-lighter: #D4D8DC;
  --color-white: #FFF;

  --color-primary-indicator: #C3BFB3;
  --color-primary-indicator-active: #3E4B41;
  --color-secondary-indicator: #777D61;
  --color-secondary-indicator-active: #E7DDD3;


  /* Font Variable */
  --font-header: 'LidoSTFCE', sans-serif;
  --font-default: 'Gotham', sans-serif;
  --font-handwrite: 'Gotham', sans-serif;
  --font-th: 'FCMinimal';
}