@media only screen and (max-width: 1199px) {

  /* mobile size */
  .test-responsive {
    background-color: darkgoldenrod;
  }

  p>br.mobile-br {
    display: none;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .fs-custom-1 {
    font-size: 2.55rem !important;
  }

  .fs-custom-2 {
    font-size: 2.55rem !important;
  }

  .fs-custom-3 {
    font-size: 2.4rem !important;
  }

  .fs-custom-5 {
    font-size: 1rem !important
  }

  #main-menu .logo {
    height: 16px;
  }

  .fs-caption-1 {
    font-size: 26px;
    line-height: 1;
  }

  .fs-custom-1 {
    font-size: 1.6rem !important;
  }

  .fs-custom-2 {
    font-size: 1.8rem !important;
  }

  .fs-custom-3 {
    font-size: 1.6rem !important;
  }

  .fs-custom-5 {
    font-size: 0.8rem !important;
  }

  .fs-custom-6 {
    /* font-size: 2.2rem !important; */
    font-size: 1.5rem !important;
  }

  .bg-custom-height {
    height: 100px;
  }

  .mb-home-accom {
    margin-bottom: 0px;
  }

  .mt-home-location {
    margin-top: 40px;
  }

  .btn-explore {
    width: 8.5rem;
    border-top-left-radius: 1.4rem;
    border-top-right-radius: 1.4rem;
    padding-top: 0.4rem;
    padding-bottom: 0.1rem;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 1px;
  }

  #footer .logo {
    height: 16px;
  }

  #footer .social-icon {
    width: 20px;
  }

  #footer .bg-bar,
  #footer .footer-content {
    height: 60px;
  }

  #footer .container-footer {
    height: 90px;
  }

  .header-lg {
    font-size: 42px;
    text-shadow: 8px 8px 8px #222;
    letter-spacing: 4px;
    line-height: 0.8;
    z-index: 9999;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .subheader-lg {
    z-index: 99;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0px;
    text-shadow: 3px 3px 10px #333;
    color: var(--body-bg);
  }

  .custom-header {
    z-index: 99;
    padding-top: 0px;
    padding-bottom: 0px;
    /* padding-bottom: 60px; */
    margin-bottom: 110px;
    /* margin-bottom: 60px; */
    margin-top: -15px;
  }

  .custom-body-bg {
    margin-top: -55px;
    height: 650px;
    margin-bottom: 90px;
  }

  .ongrid.custom-header {
    z-index: 99;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: -15px;
  }

  .ongrid .custom-body-bg {
    margin-top: -95px;
    height: 650px;
    margin-bottom: 90px;
  }

  .ongrid .overlap-content {
    margin-top: 0px;
  }

  .offgrid.custom-header {
    z-index: 99;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: -15px;
  }

  .offgrid .custom-body-bg {
    margin-top: -95px;
    height: 650px;
    margin-bottom: 90px;
  }

  .offgrid .custom-text {
    margin-top: 0px;
  }

  .carcamping.custom-header {
    z-index: 99;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: -15px;
  }

  .carcamping .custom-body-bg {
    margin-top: -95px;
    height: 650px;
    margin-bottom: 90px;
  }

  .carcamping .custom-text {
    margin-top: 0px;
  }

  .custom-body-content {
    padding-top: 80px;
    padding-bottom: 60px;
    margin-bottom: 60px;
  }

  .slide-content {}

  .custom-indicator {
    width: 7px;
    height: 7px;
    border-radius: 10px;
  }

  .content-2 {
    margin-top: 3rem;
    /* margin-top: 10rem; */
  }

  .content-2.with-booking {
    margin-top: 5rem;
  }

  .content-2 .slide-object {
    margin-top: -80px;
  }

  .content-2 .slide-object-zone {
    margin-top: 2rem;
    margin-bottom: -13rem;
  }

  .btn-booknow {
    width: 220px;
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .location-header {
    margin-top: -60px;
    margin-bottom: 120px;
    padding-bottom: 0px;
  }

  .layer-container .layer-content-text.top-header {
    background-color: var(--color-primary);
    padding-top: 11rem;
    padding-left: 32%;
    padding-right: 5rem;
    padding-bottom: 5rem;
  }

  .layer-container .layer-content-text.top-header.on-left {
    padding-top: 6rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 5rem;
  }

  .layer-container .layer-content-text.top-header.with-booking {
    padding-bottom: 5rem;
  }

  /* 
  .layer-container .layer-content-text.top-header.on-left {
    padding-top: 6rem;
    padding-left: 3rem;
    padding-right: 32%;
    padding-bottom: 5rem;
  } */

  .location-sign {
    width: 9rem;
  }

  .eden .content-text {
    margin-top: 0px;
  }

  .content-top {
    margin-bottom: 40px;
  }

  .about .top {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .about .logo {
    width: 300px;
  }

  .booking {
    margin-top: 20px;
  }

  .booking .sign {
    width: 80%;
  }

  .layer-container.top-layer {
    margin-top: -50px;
  }

  .layer-container .layer-content-text.top-header {
    background-color: var(--color-primary);
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 10rem;
  }

  .layer-container .layer-content-text.top-header.with-booking {
    padding-bottom: 10rem;
  }

  .layer-container .layer-content-slide {
    padding-top: 0;
    margin-top: -8rem;
  }

  .ongrid .layer-container .layer-content-slide {
    padding-top: 0;
    margin-top: -8rem;
  }

  .offgrid .layer-container .layer-content-slide {
    padding-top: 0;
    margin-top: -8rem;
  }

  .carcamping .layer-container .layer-content-slide {
    padding-top: 0;
    margin-top: -8rem;
  }

  .carousel-indicators {
    margin-bottom: 0;
  }

  .carousel-indicators [data-bs-target] {
    width: 7px;
    height: 7px;
  }

  .glamping-indicator-primary .carousel-indicators,
  .glamping-indicator-secondary .carousel-indicators,
  .glamping-indicator-tertiary .carousel-indicators,
  .ongrid-indicator-primary .carousel-indicators,
  .offgrid-indicator-primary .carousel-indicators,
  .carcamping-indicator-primary .carousel-indicators,
  .location-indicator .carousel-indicators {
    margin: 0 !important;
    bottom: -30px;
  }

  .glamping-indicator-primary .carousel-indicators button,
  .glamping-indicator-secondary .carousel-indicators button,
  .glamping-indicator-tertiary .carousel-indicators button,
  .ongrid-indicator-secondary .carousel-indicators button,
  .offgrid-indicator-secondary .carousel-indicators button,
  .carcamping-indicator-secondary .carousel-indicators button,
  .location-indicator .carousel-indicators button {
    width: 8px;
    height: 8px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .glamping-indicator-tertiary .carousel-indicators {
    justify-content: right;
  }

  .glamping-indicator-tertiary .carousel-indicators button {
    background-color: var(--color-primary-indicator);
  }

  .glamping-indicator-tertiary .carousel-indicators button.active {
    background-color: var(--color-primary-indicator-active);
  }

  .location-indicator .carousel-indicators {
    justify-content: left;
  }

  .location-indicator .carousel-indicators button {
    background-color: var(--color-primary-indicator);
  }

  .location-indicator .carousel-indicators button.active {
    background-color: var(--color-primary-indicator-active);
  }

  .facility-list li {
    padding: 0;
    margin: 0;
    width: 100%;
    display: inline-block;
  }

  .location-offer-slide {
    margin-bottom: -5rem;
  }

  .bg-about {
    width: 100%;
    margin-top: -10rem;
    z-index: -1;
  }

  .bg-about.left {
    left: 0;
    padding: 11rem 5rem 3rem 5rem;
  }

  .bg-about.right {
    right: 0;
    padding: 11rem 5rem 3rem 5rem;
  }

  .custom-carousel.right-top-round .carousel-inner .carousel-item {
    border-radius: 0 7em 0 0;
    /*120px*/
  }

  .custom-carousel.left-top-round .carousel-inner .carousel-item {
    border-radius: 7em 0 0 0;
    /*120px*/
  }
}

@media only screen and (max-width: 490px) {

  /* old value is 576 */
  .offgrid .layer-container .layer-content-text.top-header {
    background-color: var(--color-primary);
    padding-top: 7rem;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 10rem;
  }
}

@media only screen and (max-width: 460px) {

  /* old value is 576 */
  .ongrid .layer-container .layer-content-text.top-header {
    background-color: var(--color-primary);
    padding-top: 7rem;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 10rem;
  }

  .booking {
    min-height: calc(100vh - 230px);
  }
}