@media only screen and (max-width: 567px) {
  .test-responsive {
    background-color: cornflowerblue;
  }
}

@media only screen and (max-width: 485px) {
  .test-responsive {
    background-color: crimson;
  }

  .layer-container.top-layer {
    margin-top: -80px !important;
  }
}